<template>
    <div class="flex justify-center">
        <el-upload class="upload-demo" accept=".txt,.xml" action="" :before-upload="uploadFile" :file-list="fileList" :limit="1" :show-file-list="false" v-loading="$waiting.is('uploading')">
            <el-button type="primary">Välj fil</el-button>
            <div slot="tip" class="el-upload__tip">fil från banken</div>
        </el-upload>
    </div>
</template>
<script>
import Api from "../bank_files.api";

export default {
    data() {
        return {
            fileList: [],
        };
    },

    methods: {
        beforeUpload() {},

        async uploadFile(event) {
            this.$waiting.start("uploading");
            localStorage.removeItem("bank_files");
            let formData = new FormData();
            formData.append("files", event);
            const responseData = await Api.uploadFile(formData);
            this.$notify.success({ title: "Utfört" });
            this.$emit("uploaded", responseData);
            this.$waiting.end("uploading");
            return new Promise.reject();
        },
    },
};
</script>
